import React from "react";

const Cta = () => {
  return (
    <>
      <section id="contact">
        <div
          className="content has-text-centered"
          style={{
            padding: "70px",
            borderRadius: "0px",
            backgroundColor: "#E8D3D0",
          }}
        >
          <h2 className="title spec-h">Let's have a talk...</h2>
          <p className="subtitle">
            We invite you to chat with us or drop us a message.
          </p>
          <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://shop.shroom4you.com/contact/"
            className="btn btn-outline-dark"
          >
            get in touch
            <span role="img" aria-label="mushroom">
              🍄
            </span>
          </a>
        </div>
      </section>
    </>
  );
};

export default Cta;
